import request from './request.js';
// 获取用户跟进列表
export function list_user_data(data) {
    return request({
        url: '/market-user/list-market-user-follow',
        method: 'get',
        params: data
    })
}

// 推广用户跟进-添加
export function add_market_customer(data) {
    return request({
        url: '/market-user/add-market-user-customer',
        method: 'POST',
        data
    })
}

// 获取用户跟进列表
export function list_market_customer(data) {
    return request({
        url: '/market-user/list-market-user-customer',
        method: 'get',
        params: data
    })
}

// 员工数据
export function  institution_employee_list(params) {
    return request({
        url: '/market-user/list/data/employee',
        method: 'GET',
        params
    });
}

// 计划详情
export function  institution_employee_plan(params) {
    return request({
        url: '/market-user/list/data/employee/plan',
        method: 'GET',
        params
    });
}
