<template>
  <div>
    <div class="top">
      <div>
        <el-input
        placeholder="请输入手机号"
        style="width: 180px;"
        v-model="queryList.keyword"
        clearable
        @input="inputText"
      ></el-input>
      <el-button
        @click="changeCondition"
        icon="el-icon-search"
        style="margin-right: 35px"
        type="primary"
        >搜索
      </el-button>
      </div>
      <div style="display: flex; align-items: center;">
        <el-radio-group v-model="timeText" @change="time" style="margin-right: 15px;">
          <el-radio-button label="今日"></el-radio-button>
          <el-radio-button label="昨日"></el-radio-button>
        </el-radio-group>
      <el-date-picker
        height="30"
        v-model="timeValue"
        type="daterange"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="changeTime"
        value-format="yyyy-MM-dd"
      >
      </el-date-picker>
      </div>
      <!-- <el-select
        style="width: 130px; margin: 8px 0"
        @change="changeCondition"
        clearable
        placeholder="用户状态"
        v-model="queryList.userState"
      >
        <el-option
          :key="item.id"
          :label="item.name"
          :value="item.id"
          v-for="item in optionState"
        >
        </el-option>
      </el-select> -->
    </div>
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      :height="tableHeight"
    >
      <el-table-column label="推广机构手机号" prop="phone"> </el-table-column>
      <el-table-column label="产品名" prop="institutionName">
        <template slot-scope="scope">
          <div>
            {{ scope.row.institutionName ? scope.row.institutionName : "-" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="机构公司名" prop="companyName">
        <template slot-scope="scope">
          <div>
            {{ scope.row.companyName ? scope.row.companyName : "-" }}
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="用户状态" prop="userState">
        <template slot-scope="scope">
          <div v-if="!scope.row.userState">
            已注册
          </div>
          <div v-else-if="scope.row.userState == 1">
            已登录
          </div>
          <div v-else-if="scope.row.userState == 2">
            已认证
          </div>
          <div v-else>
            已充值
          </div>
        </template>
      </el-table-column> -->
      <el-table-column label="总充值金币" prop="payMoney"> </el-table-column>
      <el-table-column label="总消耗金币" prop="pushMoney"> </el-table-column>
      <el-table-column label="总赠送金币" prop="refundMoney"> </el-table-column>
      <el-table-column label="员工数据" width="200px">
        <template slot-scope="scope">
          <div style="width: 100%; display: flex; justify-content: center">
            <el-button @click="getLook(scope.row)" size="small" type="primary"
              >查看
            </el-button>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="feedback" label="跟进记录" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="name" label="跟单情况">
        <template slot-scope="scope"
          ><el-button
            size="small"
            type="primary"
            @click="Look(scope.row.id)"
            >查看</el-button
          ></template
        >
      </el-table-column> -->
    </el-table>
    <pagination
      :limit.sync="queryList.pageSize"
      :page.sync="queryList.pageIdx"
      :total="total"
      @pagination="pagination"
    />
    <el-dialog title="跟单情况" :visible.sync="outerVisible">
      <el-dialog
        width="30%"
        title="新增"
        :visible.sync="innerVisible"
        append-to-body
      >
        <el-input
          v-model="feedback"
          placeholder="请输入您反馈的内容"
          style="margin-bottom: 15px"
        ></el-input>
        <div slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false">取 消</el-button>
          <el-button type="primary" @click="addLook">新 增</el-button>
        </div>
      </el-dialog>
      <el-table
        :data="lookList"
        border
        style="width: 100%"
        :header-cell-style="{
          textAlign: 'center',
          color: '#000',
        }"
        :cell-style="{ textAlign: 'center' }"
      >
        <el-table-column prop="createTime" label="反馈时间"> </el-table-column>
        <el-table-column prop="feedback" label="反馈内容"> </el-table-column>
      </el-table>
      <!-- <div class="pagination-container">
        <el-pagination
          background
          @size-change="handleSizeChangeDialog"
          @current-change="handleCurrentChangeDialog"
          :current-page.sync="status.pageIdx"
          :page-sizes="[10, 20, 30, 50]"
          :page-size.sync="status.pageSize"
          layout="total, prev, pager, next, sizes, jumper"
          :total="lookTotal"
        >
        </el-pagination>
      </div> -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="outerVisible = false">取 消</el-button>
        <el-button type="primary" @click="innerVisible = true">新增</el-button>
      </div>
    </el-dialog>
    <!-- 查看 -->
    <el-dialog
      :visible.sync="showLook"
      width="770px"
      :center="true"
      append-to-body
    >
      <el-table
        border
        :data="lookData"
        ref="multipleTable"
        style="width: 100%"
        :height="tableHeightLook"
      >
        <el-table-column label="员工名称" prop="userName"> </el-table-column>
        <el-table-column label="消耗金币" prop="payMoney"> </el-table-column>
        <el-table-column label="用户申请数量" prop="pushNum"> </el-table-column>
        <el-table-column label="编辑">
          <template slot-scope="scope">
            <el-button
              @click="getPlanList(scope.row)"
              size="mini"
              type="primary"
              >计划详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showLook = false" type="primary">确 认</el-button>
      </div>
      <div :class="{ hidden: hidden }" class="pagination-container">
        <el-pagination
          background
          @size-change="handleSizeLook"
          @current-change="handleCurrentLook"
          :current-page.sync="lookList.pageIdx"
          :page-sizes="[10, 20, 30, 50]"
          :page-size.sync="lookList.pageSize"
          layout="total, prev, pager, next, sizes, jumper"
          :total="lookTotal"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="showPlan"
      :title="getTitle"
      width="1060px"
      :center="true"
      append-to-body
    >
      <el-table
        border
        ref="multipleTable"
        :data="planData"
        tooltip-effect="dark"
        style="width: 100%"
        :height="tableHeightPlan"
      >
        <el-table-column label="计划名称" prop="planName" width="110">
        </el-table-column>
        <el-table-column label="状态" prop="state" width="60">
          <template slot-scope="scope">
            <div v-if="scope.row.frozenState">冻结中</div>
            <div v-else>
              {{ scope.row.state ? "开启中" : "关闭中" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="城市" prop="city" width="80">
        </el-table-column>
        <el-table-column label="出价" prop="price" width="60">
        </el-table-column>
        <el-table-column prop="limitNum" label="限量" width="60">
        </el-table-column>
        <el-table-column prop="age" label="年龄限制" width="80">
          <template slot-scope="scope">
            <div>{{ scope.row.minAge + "-" + scope.row.maxAge }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="age" label="额度限制" width="80">
          <template slot-scope="scope">
            <div>
              {{
                !scope.row.minMoney && !scope.row.maxMoney
                  ? "不限制"
                  : scope.row.minMoney + "-" + scope.row.maxMoney + "万"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="cond" label="条件限制">
          <template slot-scope="scope">
            <div v-if="scope.row.cond == '无限制'">无限制</div>
            <div v-else>
              {{ scope.row.condValue.slice(0, -1) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="task" label="定时" width="150px">
          <template slot-scope="scope">
            <div
              v-for="(item, index) in scope.row.task.split('|')"
              :key="index"
            >
              {{ item }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="pushNum" label="用户申请数量" width="70px">
          <template slot-scope="scope">
            <div>
              {{ scope.row.pushNum ? scope.row.pushNum : 0 }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showPlan = false">确认</el-button>
      </span>
      <pagination
        :limit.sync="planList.pageSize"
        :page.sync="planList.pageIdx"
        :total="planTotal"
        @pagination="paginationLook"
      />
    </el-dialog>
  </div>
</template>

<script>
function jialing(num) {
  if (num >= 10) {
    return num
  } else {
    return '0' + num
  }
}

var day1 = new Date()
day1.setTime(day1.getTime())
var s1 =
  day1.getFullYear() +
  '-' +
  jialing(day1.getMonth() + 1) +
  '-' +
  jialing(day1.getDate())
var day3 = new Date()
day3.setTime(day3.getTime() + 24 * 60 * 60 * 1000)
var s3 =
  day3.getFullYear() +
  '-' +
  jialing(day3.getMonth() + 1) +
  '-' +
  jialing(day3.getDate())
var day2 = new Date()
day2.setTime(day2.getTime() - 24 * 60 * 60 * 1000)
var s2 =
  day2.getFullYear() +
  '-' +
  jialing(day2.getMonth() + 1) +
  '-' +
  jialing(day2.getDate())

import Pagination from "../../components/Pagination/index.vue";

import {
  list_user_data,
  list_market_customer,
  add_market_customer,
  institution_employee_list,
  institution_employee_plan,
} from "../../api/pushUser";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      timeText: "今日",
      timeValue: [s1, s3],
      instId: "",
      getTitle: "",
      hidden: false,
      showPlan: false,
      tableHeightPlan:
        (document.documentElement.clientHeight * 0.5).toString() + "px",
      planList: {
        pageIdx: 1,
        pageSize: 10,
      },
      planTotal: 0,
      planData: [],
      showLook: false,
      tableHeightLook:
        (document.documentElement.clientHeight * 0.5).toString() + "px",
      lookList: {
        pageIdx: 1,
        pageSize: 10,
      },
      lookTotal: 0,
      lookData: [],
      feedback: "",
      status: {
        pageIdx: 1,
        pageSize: 10,
        institutionUserId: "",
      },
      lookTotal: 0,
      outerVisible: false,
      innerVisible: false,
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(2030, 10, 1),
      queryList: {
        pageIdx: 1,
        pageSize: 10,
        keyword: "",
        endTime: s3,
        startTime: s1,
        // userState: ''
      },
      total: 0,
      tableData: [],
      tableHeight:
        (document.documentElement.clientHeight * 0.67).toString() + "px",
      optionState: [
        {
          name: "已注册",
          id: 0,
        },
        {
          name: "已登录",
          id: 1,
        },
        {
          name: "已认证",
          id: 2,
        },
        {
          name: "已充值",
          id: 3,
        },
      ],
    };
  },
  mounted() {
    this.setTableHeight();
  },
  watch: {
    showPlan(newval) {
      if (!newval) {
        this.getLookList()
      }
    },
    showLook(newval) {
      if (!newval) {
        this.LoanList()
      }
    },
    innerVisible(newval) {
      if (!newval) {
        this.feedback = "";
      }
    },
    outerVisible(newval) {
      if (!newval) {
        this.LoanList();
      }
    },
  },
  methods: {
    time() {
      if (this.timeText === "今日") {
        this.queryList.startTime = s1;
        this.queryList.endTime = s3;
        this.timeValue = [];
        this.timeValue[0] = s1;
        this.timeValue[1] = s3;
      } else if (this.timeText === "昨日") {
        this.queryList.startTime = s2;
        this.queryList.endTime = s1;
        this.timeValue = [];
        this.timeValue[0] = s2;
        this.timeValue[1] = s1;
      }
      this.LoanList();
    },
    changeTime(val) {
      this.queryList.startTime = val ? val[0] : ''
      this.queryList.endTime = val ? val[1] : ''
      if (val && val[0] == s1 && val[1] == s3) {
        this.timeText = "今日";
      } else if (val && val[0] == s2 && val[1] == s1) {
        this.timeText = "昨日";
      } else {
        this.timeText = "";
      }
      this.LoanList()
    },
    getPlanList(data) {
      console.log(data);
      this.getTitle = data.userName;
      this.instId = data.instId
      institution_employee_plan({
        ...this.planList,
        instId: data.instId,
        startTime: this.queryList.startTime,
        endTime: this.queryList.endTime
      }).then((res) => {
        if (res.code === 200) {
          this.planData = res.data.records;
          this.planTotal = res.data.total;
          this.showPlan = true;
        }
      });
    },
    // 计划分页
    paginationLook(params) {
      if (params.page) {
        this.planList.pageIdx = params.page;
      } else {
        this.planList.pageIdx = 1;
        this.planList.pageSize = params.limit;
      }
      institution_employee_plan({
        ...this.planList,
        instId: this.instId,
        startTime: this.queryList.startTime,
        endTime: this.queryList.endTime
      }).then((res) => {
        if (res.code === 200) {
          this.planData = res.data.records;
          this.planTotal = res.data.total;
          this.showPlan = true;
        }
      });
    },
    handleCurrentLook(pageIdx) {
      this.lookList.pageIdx = pageIdx
      this.getLookList()
    },
    handleSizeLook(pageSize) {
      this.lookList.pageIdx = 1
      this.lookList.pageSize = pageSize
      this.getLookList()
    },
    getLookList() {
      institution_employee_list({
        ...this.lookList,
        instUserId: this.instUserId,
        startTime: this.queryList.startTime,
        endTime: this.queryList.endTime
      }).then((res) => {
        if (res.code === 200) {
          this.lookData = res.data.records
          this.lookTotal = res.data.total
          this.showLook = true
        }
      })
    },
    getLook(data) {
      this.instUserId = data.instUserId;
      institution_employee_list({
        ...this.lookList,
        instUserId: data.instUserId,
        startTime: this.queryList.startTime,
        endTime: this.queryList.endTime,
      }).then((res) => {
        if (res.code === 200) {
          this.lookData = res.data.records;
          this.lookTotal = res.data.total;
          this.showLook = true;
        }
      });
    },
    addLook() {
      if (!this.feedback) {
        this.$message.error("请输入您反馈的内容");
        return;
      }
      add_market_customer({
        feedback: this.feedback,
        institutionUserId: this.status.institutionUserId,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "新增成功",
            type: "success",
          });
          this.innerVisible = false;
          list_market_customer(this.status).then((res) => {
            this.lookList = res.data;
            this.lookTotal = res.data.total;
          });
        }
      });
    },
    // handleCurrentChangeDialog(pageIdx) {
    //   this.status.pageIdx = pageIdx;
    //   list_market_customer(this.status).then((res) => {
    //     this.lookList = res.data;
    //     this.lookTotal = res.data.total;
    //   });
    // },
    // handleSizeChangeDialog(pageSize) {
    //   this.status.pageSize = pageSize;
    //   this.status.pageIdx = 1;
    //   list_market_customer(this.status).then((res) => {
    //     this.lookList = res.data;
    //     this.lookTotal = res.data.total;
    //   });
    // },
    Look(data) {
      this.status.institutionUserId = data;
      this.outerVisible = true;
      list_market_customer(this.status).then((res) => {
        this.lookList = res.data;
        this.lookTotal = res.data.total;
      });
    },
    LoanList() {
      const dto = this.queryList;
      list_user_data(dto).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    inputText() {
      this.queryList.pageIdx = 1;
      if (!this.queryList.keyword) {
        this.LoanList();
      }
    },
    //设置table高度
    setTableHeight() {
      this.$nextTick(() => {
        this.tableHeight =
          (document.documentElement.clientHeight * 0.67).toString() + "px";
      });
    },
    //分页
    pagination(params) {
      if (params.page) {
        this.queryList.pageIdx = params.page;
      } else {
        this.queryList.pageIdx = 1;
        this.queryList.pageSize = params.limit;
      }
      this.LoanList();
    },
    //选择条件
    changeCondition() {
      this.queryList.pageIdx = 1;
      this.LoanList();
    },
  },
  created() {
    this.LoanList();
  },
};
</script>

<style scoped>
.content {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}

.content div {
  float: left;
  margin-left: 100px;
}

.content div p {
  margin-bottom: 10px;
}

::v-deep .el-date-editor .el-range-separator {
  width: 10%;
}

::v-deep .el-radio {
  margin-right: 15px;
}

::v-deep .el-radio__label {
  padding-left: 3px;
}

::v-deep thead th .cell {
  color: #000;
  text-align: center;
}

::v-deep tbody td .cell {
  text-align: center;
}

::v-deep .time-select input::-webkit-input-placeholder {
  color: #000;
}

::v-deep .el-range__close-icon {
  width: 100px;
  height: 100px;
  /* background: rgb(95, 94, 94); */
}

.space-line {
  display: inline-block;
  width: 15px;
  text-align: center;
}

.faliedMsg {
  width: 300px;
  font-size: 12px;
  font-weight: 400;
  color: #9b9b9b;
  margin-bottom: 0.2703rem;
  display: flex;
  justify-content: center;
}

::v-deep tbody .cell {
  color: #979898;
  font-size: 13px;
}

::v-deep .cell {
  text-align: center;
}

::v-deep .el-table thead {
  color: #000;
}
::v-deep thead th {
  background: #f7f7f7;
}
::v-deep tbody td .cell {
  text-align: center;
}

::v-deep .el-table {
  overflow-y: auto;
}

.top {
  margin: 28px 0;
  display: flex;
  flex-wrap: wrap;
}

.pagination-container {
  background: #fff;
  text-align: center;
  padding-top: 15px;
}
.pagination-container.hidden {
  display: none;
}
</style>
